/* Base layout */
.deity-path-page {
  min-height: 100vh;
  background: #0a0a0a;
  color: white;
  padding-bottom: 400px;  /* Add space at bottom for dropdown */
}

/* Hero section */
.deity-path-hero {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.deity-path-background {
  width: 600px;  /* Double the size from 300px to 600px */
  object-fit: contain;
  display: block;
  margin: 0 auto;
  max-width: 100%; /* Make sure image doesn't overflow container */
}

.deity-path-title {
  width: 100%;
  text-align: center;
  margin-top: -2rem;  /* Pull title up to remove gap */
}

.deity-path-title h1 {
  font-size: 4rem;
  margin: 0;
  padding: 0;
}

/* Content section */
.deity-path-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

/* Content blocks */
.content-block {
  margin-bottom: 2rem;
  padding: 1.5rem;
  background: rgba(74, 158, 255, 0.05);
  border: 1px solid rgba(74, 158, 255, 0.1);
  border-radius: 8px;
}

.content-block h2 {
  color: #4a9eff;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.content-block p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: rgba(255, 255, 255, 0.9);
}

/* Traits */
.trait {
  padding: 1rem;
  background: rgba(74, 158, 255, 0.05);
  border: 1px solid rgba(74, 158, 255, 0.1);
  border-radius: 8px;
  margin-bottom: 1rem;
}

.trait-header h3 {
  color: #4a9eff;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}

.trait p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.8);
}

/* Lists */
.specializations-list {
  list-style: none;
  padding-left: 0;
  margin-top: 1rem;
}

.specializations-list li {
  margin: 0.5rem 0;
  padding-left: 1.5rem;
  position: relative;
  color: rgba(255, 255, 255, 0.8);
}

.specializations-list li::before {
  content: "•";
  color: #4a9eff;
  position: absolute;
  left: 0;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
  .deity-path-title h1 {
    font-size: 2.5rem;
  }
  
  .content-block h2 {
    font-size: 1.5rem;
  }
  
  .trait-header h3 {
    font-size: 1.2rem;
  }

  .deity-path-background {
    width: 100%;
    max-width: 400px;
  }

  .modal-image-container {
    height: auto;
    margin: 1rem;
  }

  .modal-image {
    width: 100%;
    height: auto;
    max-height: 80vh;
  }
} 

/* Modal styles */
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-image-container {
  position: relative;
  height: 85vh;
  margin: 2rem 0;
  width: 100%;
  max-width: 1200px;
  padding: 0 1rem;
}

.modal-image {
  height: 100%;
  width: auto;
  max-height: calc(100vh - 4rem);
  max-width: 100%;
  object-fit: contain;
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.modal-download {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: white;
  text-decoration: none;
  font-size: 24px;
}

/* Path navigation */
.paths-navigation {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 2rem auto;
}

.paths-navigation h2 {
  color: #4a9eff;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.paths-list {
  display: none;
  position: absolute;
  background: rgba(10, 10, 10, 0.95);
  border: 1px solid rgba(74, 158, 255, 0.2);
  border-radius: 4px;
  min-width: 200px;
  z-index: 1000;
  top: 100%;  /* Position below the button */
  left: 50%;
  transform: translateX(-50%);
  list-style: none;
  padding: 0;
}

.paths-navigation:hover .paths-list {
  display: flex;
  flex-direction: column;
}

.path-link {
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem;
  transition: all 0.3s ease;
  white-space: nowrap;
}

.path-link:hover {
  background: rgba(74, 158, 255, 0.2);
} 

/* Add this to center the menu */
.menu-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 2rem 0;
}

.deity-paths {
  margin-top: 3rem;
  text-align: center;
  display: inline-block;  /* Make it only as wide as content */
} 